
.itemm {
    transition: all 0.6s ease-in-out;
}

.itemm-enter {
    opacity: 0.01;
    transform: translateX(-100%);
}

.itemm-enter.itemm-enter-active {
        opacity: 1;
        transition: opacity 600ms ease-in;
}