.targetSummary {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 0.5fr 0.5fr 0.5fr 0.5fr;
    gap: 0px 0px;
    height: 100%;
    grid-template-areas:
        "SumControl SumVal1Name SumVal2Name"
        "SumName SumVal1Name SumVal2Name"
        "SumSmalTxt SumVal1 SumVal2"
        "SumUpdate SumVal1 SumVal2";
    border: dotted 1px;
    border-radius: 10px;
    padding: 5px;
}

.SumControl {
    grid-area: SumControl;
    font-size: 1vw;
}

.SumName {
    grid-area: SumName;
    max-height: 20px;
    font-size: 1vw;
}

.SumSmalTxt {
    grid-area: SumSmalTxt;
}

.SumUpdate {
    grid-area: SumUpdate;
}

.SumVal1Name {
    grid-area: SumVal1Name;
    font-size:1vw;
}

.SumVal2Name {
    grid-area: SumVal2Name;
    font-size: 1vw;
}

.SumVal1 {
    grid-area: SumVal1;
    
}

.SumVal2 {
    grid-area: SumVal2;
    
}
