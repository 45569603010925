
.lll {
    padding: 30px;
    width: 250px;
    margin: 30px;
}

.plusesLeft {
    border: 1px solid;
    border-right: 0;
    border-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(180, 180, 180, 1) 50%, rgba(255, 255, 255, 0) 100%);
    border-image-slice: 1;
}

.plusesRight {
    border: 1px solid;
    border-left: 0;
    border-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(180, 180, 180, 1) 50%, rgba(255, 255, 255, 0) 100%);
    border-image-slice: 1;
}


.plusesBottom {
    border: 1px solid;
    border-Top: 0;
    border-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(180, 180, 180, 1) 50%, rgba(255, 255, 255, 0) 100%);
    border-image-slice: 1;
}

.plusesX {
    border: 1px solid;
    border-Top: 0;
    border-bottom: 0;
    border-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(180, 180, 180, 1) 50%, rgba(255, 255, 255, 0) 100%);
    border-image-slice: 1;
}


.plusesTop {
    border: 1px solid;
    border-bottom: 0;
    border-image: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(180, 180, 180, 1) 50%, rgba(255, 255, 255, 0) 100%);
    border-image-slice: 1;
}



.containerFirma {
    display: grid;
    grid-template-columns: 0.75fr 2.25fr 3fr 0.25fr 0.25fr;
    grid-template-rows: 0.5fr 0.5fr 0.5fr 1fr;
    grid-auto-rows: 1fr;
    gap: 0px 0px;
    grid-auto-flow: column dense;
    justify-content: center;
    grid-template-areas:
        "LogoArea NameArea RestArea MpArea ControlArea"
        "LogoArea NameArea RestArea MpArea ControlArea"
        "LogoArea NameArea RestArea MpArea ControlArea"
        "LogoArea NameArea SpecialArea MpArea ControlArea";
    height: 20%;
    border: solid;
    border-width: thin;
    border-radius: 10px;
    margin: 15px;
    padding: 15px;
    border-color: darkgray;
}

    .containerFirma:hover {
        -moz-box-shadow: 0 0 10px #ccc;
        -webkit-box-shadow: 0 0 10px #ccc;
        box-shadow: 0 0 10px #ccc;
        background-color: #f2f2f2;
    }


.container22 {
    display: grid;
    grid-template-columns: 0.75fr 1.25fr 4fr 0.25fr 0.25fr;
    grid-template-rows: 0.5fr 0.5fr 0.5fr 1fr;
    grid-auto-rows: 1fr;
    gap: 0px 0px;
    grid-auto-flow: column dense;
    justify-content: center;
    grid-template-areas:
        "LogoArea NameArea RestArea MpArea ControlArea"
        "LogoArea NameArea RestArea MpArea ControlArea"
        "LogoArea NameArea RestArea MpArea ControlArea"
        "LogoArea NameArea SpecialArea MpArea ControlArea";
    height: 20%;
    border: solid;
    border-width: thin;
    border-radius: 10px;
    margin: 15px;
    padding: 15px;
    border-color: darkgray;
}
    .container22:hover {
        -moz-box-shadow: 0 0 10px #ccc;
        -webkit-box-shadow: 0 0 10px #ccc;
        box-shadow: 0 0 10px #ccc;
        background-color: #f2f2f2;
    }

.LogoArea {
    justify-self: center;
    align-self: center;
    grid-area: LogoArea;
    width: 80%;
    height: 80%;
    display: table;
}

.NameArea {
    justify-self: start;
    align-self: center;
    grid-area: NameArea;
    width: 90%;
    height: 80%;
    display: table;
}

.RestArea {
    justify-self: start;
    align-self: center;
    grid-area: RestArea;
    width: 95%;
    height: 95%;
}

.SpecialArea {
    justify-self: end;
    align-self: center;
    grid-area: SpecialArea;
    width: 90%;
    
    padding-top: 10px;
}

.MpArea {
    justify-self: center;
    align-self: center;
    grid-area: MpArea;
    width: 100%;
    height: 100%;
    display: table;
}

.ControlArea {
    justify-self: center;
    align-self: center;
    grid-area: ControlArea;
    width: 80%;
    height: 80%;
    display: table; 
}

@media only screen and (max-width: 600px) {
    .ControlArea {
        display: none;
    }
}