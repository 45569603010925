/* @import url('https://fonts.googleapis.com/css?family=Noto+Sans:400,700'); */
/* noto-sans-regular - latin */
@font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    src: url('fonts/noto-sans-v27-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''), url('fonts/noto-sans-v27-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('fonts/noto-sans-v27-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('fonts/noto-sans-v27-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('fonts/noto-sans-v27-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('fonts/noto-sans-v27-latin-regular.svg#NotoSans') format('svg'); /* Legacy iOS */
}
/* noto-sans-700 - latin */
@font-face {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 700;
    src: url('fonts/noto-sans-v27-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''), url('fonts/noto-sans-v27-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('fonts/noto-sans-v27-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('fonts/noto-sans-v27-latin-700.woff') format('woff'), /* Modern Browsers */
    url('fonts/noto-sans-v27-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('fonts/noto-sans-v27-latin-700.svg#NotoSans') format('svg'); /* Legacy iOS */
}

.corner-ribbon {
    width: 80px;
    background: #e43;
    position: relative;
    top: 25px;
    left: -50px;
    text-align: center;
    line-height: 20px;
    letter-spacing: 1px;
    color: #f0f0f0;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    z-index:10;
    border-radius: 5px;
}

    /* Custom styles */

    .corner-ribbon.sticky {
        position: relative;
    }

    .corner-ribbon.shadow {
        box-shadow: 0 0 3px rgba(0,0,0,.3);
    }

    /* Different positions */

    .corner-ribbon.top-left {
        
        left: 0px;
        transform: rotate(-45deg);
        -webkit-transform: rotate(0deg);
    }

    .corner-ribbon.top-right {
        top: 25px;
        right: -50px;
        left: auto;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
    }

    .corner-ribbon.bottom-left {
        top: auto;
        bottom: 25px;
        left: -50px;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
    }

    .corner-ribbon.bottom-right {
        top: auto;
        right: -50px;
        bottom: 25px;
        left: auto;
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
    }

    /* Colors */

    .corner-ribbon.white {
        background: #f0f0f0;
        color: #555;
    }

    .corner-ribbon.black {
        background: #333;
    }

    .corner-ribbon.grey {
        background: #999;
    }

    .corner-ribbon.blue {
        background: #39d;
    }

    .corner-ribbon.green {
        background: #2b957a;
    }

    .corner-ribbon.turquoise {
        background: #1b9;
    }

    .corner-ribbon.purple {
        background: #95b;
    }

    .corner-ribbon.red {
        background: #e43;
    }

    .corner-ribbon.orange {
        background: #e82;
    }

    .corner-ribbon.yellow {
        background: #ec0;
    }
