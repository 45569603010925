/* ========================================================================
     Component: typo
 ========================================================================== */

//@import url(//fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,400italic);

/* source-sans-pro-300 - latin */
@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 300;
    src: url('fonts/source-sans-pro-v21-latin-300.eot'); /* IE9 Compat Modes */
    src: local(''), url('fonts/source-sans-pro-v21-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('fonts/source-sans-pro-v21-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('fonts/source-sans-pro-v21-latin-300.woff') format('woff'), /* Modern Browsers */
    url('fonts/source-sans-pro-v21-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
    url('fonts/source-sans-pro-v21-latin-300.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-regular - latin */
@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    src: url('fonts/source-sans-pro-v21-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''), url('fonts/source-sans-pro-v21-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('fonts/source-sans-pro-v21-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('fonts/source-sans-pro-v21-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('fonts/source-sans-pro-v21-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('fonts/source-sans-pro-v21-latin-regular.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-italic - latin */
@font-face {
    font-family: 'Source Sans Pro';
    font-style: italic;
    font-weight: 400;
    src: url('fonts/source-sans-pro-v21-latin-italic.eot'); /* IE9 Compat Modes */
    src: local(''), url('fonts/source-sans-pro-v21-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('fonts/source-sans-pro-v21-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('fonts/source-sans-pro-v21-latin-italic.woff') format('woff'), /* Modern Browsers */
    url('fonts/source-sans-pro-v21-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('fonts/source-sans-pro-v21-latin-italic.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}
/* source-sans-pro-600 - latin */
@font-face {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    src: url('fonts/source-sans-pro-v21-latin-600.eot'); /* IE9 Compat Modes */
    src: local(''), url('fonts/source-sans-pro-v21-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('fonts/source-sans-pro-v21-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('fonts/source-sans-pro-v21-latin-600.woff') format('woff'), /* Modern Browsers */
    url('fonts/source-sans-pro-v21-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
    url('fonts/source-sans-pro-v21-latin-600.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}


body {
    font-family: "Source Sans Pro", sans-serif;
    color: $body-color;
    font-size: 0.875rem; // 14px
}

h1,h2,h3,h4 {
    font-weight: bold;
}

h1,h2,h3,h4,h5,h6,
.h1,.h2,.h3,.h4,.h5,.h6 {
    margin-bottom: 0.65625rem;
}

h1,.h1 {
    font-size: 2.25rem; // 36px
}

h2,.h2 {
    font-size: 1.875rem; // 30px
}

h3,.h3 {
    font-size: 1.5rem; // 24px
}

h4,.h4 {
    font-size: 1.125rem; // 18px
}

h5,.h5 {
    font-size: .875rem;
}

h6,.h6 {
    font-size: 1rem;
}

p {
    margin-bottom: .65rem; // ~10px
}
